import React from 'react';
import { useInView } from 'react-intersection-observer';

import TriangleIsosceles from '../../images/triangle-isosceles.inline.svg';
import ScrollTracker from '../scroll-tracker';

export default () => {
  const [ref, inView] = useInView({
    threshold: 1,
    rootMargin: '0px 0px -12.5% 0px',
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top } }) => {
        const offsetY = isNaN(top) ? 0 : top;

        return (
          <div
            className="relative bg-turquoise"
            style={{
              background: 'linear-gradient(270deg, #ffffff 0%, #b1f8fc 80%)',
            }}
          >
            <div
              className="absolute -ml-48 md:-ml-12 lg:-ml-24"
              style={{
                transform: `translateZ(0) rotate(180deg) translateY(${25 -
                  offsetY / 50}%)`,
              }}
            >
              <div
                style={{ width: '46.52777778vw', maxWidth: 670, minWidth: 400 }}
              >
                <TriangleIsosceles
                  fill="url(#linear-gradient-neon-lavender-to-turquoise)"
                  width="100%"
                  height="100%"
                  className="block"
                />
              </div>
            </div>
            <div className="relative z-10 px-6 sm:px-12 md:px-20 lg:pl-56 xl:px-0 py-32">
              <div className="ml-auto xl:w-4/5 xl:pl-16 xl:pr-32">
                <h2
                  ref={ref}
                  className="highlight-text-expanded text-special-red mb-10"
                >
                  <div className={`animated ${inView ? 'fadeIn' : 'fadeOut'}`}>
                    Meteoric growth – by the numbers
                  </div>
                </h2>
                <p
                  className={`animated delay-500ms body-text text-navy mb-32 ${
                    inView ? 'fadeIn' : 'fadeOut'
                  }`}
                >
                  Between May 2017 and September 2019, Afterpay’s customer-base
                  grew <span className="text-special-red">10x</span> in
                  size and the rest of the platform saw absurd
                  growth across every metric measured. In-store codes generated
                  grew from <span className="text-special-red">100</span> to{' '}
                  <span className="text-special-red">1,700,000</span>.
                  Merchant referrals — clicks out of the Afterpay platform —
                  grew from{' '}
                  <span className="text-special-red">62,000 per month</span>, to{' '}
                  <span className="text-special-red">
                    10,000,000 per month
                  </span>
                  . The iOS and Android apps were downloaded a total of{' '}
                  <span className="text-special-red">6.3 million times</span>. As
                  a whole, the Afterpay platform crossed the theshold of
                  processing over{' '}
                  <span className="text-special-red">
                    10% of all Australian e-commerce transactions{' '}
                  </span>
                  and{' '}
                  <span className="text-special-red">
                    $8 billion of global underlying sales
                  </span>{' '}
                  serviced by{' '}
                  <span className="text-special-red">700 million requests</span>{' '}
                  to the consumer servers per week. As these staggering numbers
                  continued to increase, Afterpay hit a milestone;
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </ScrollTracker>
  );
};
