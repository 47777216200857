import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';
import ScrollTracker from '../scroll-tracker';

import PolyClusterLg from '../polycluster-lg';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile ? 0 : top < height && top > -height ? top : 0;

        let rawProgress,
          progress = 1;

        if (offsetY !== 0) {
          rawProgress = (height - top) / height;
          progress = rawProgress >= 1 ? 1 + (rawProgress % 1) / 2 : rawProgress;
        }

        return (
          <div className="hero">
            <div className="bg">
              <div className="bg-content">
                <div
                  className="vapor-wave"
                  style={{
                    transform: `translateZ(0) scale(4) rotateY(-${54 + progress * 6}deg)`,
                  }}
                >
                  <div className="plane" />
                </div>
              </div>
            </div>
            <div className="hero-text-container">
              <HeroText
                date="May 2017 – Present Day"
                lineA="Hyper"
                lineB="Growth"
              />
              <HighlightTextHeading
                bgColor="special-red"
                textColor="white"
                lineColor="neon-lavender"
              >
                A tiger by the tail
              </HighlightTextHeading>
            </div>
            {!mobile && (
              <div className="absolute" style={{ width: '30vw', right: '25vw', bottom: 0 }}>
                <PolyClusterLg
                  a="#C99CEF"
                  b="#B1F8FC"
                  e="#B1F8FC"
                  offsetY={offsetY}
                  className="w-full"
                />
              </div>
            )}
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
