import React from 'react';
import { useInView } from 'react-intersection-observer';

import SevenHundredMil from './uptime/five-hundred-mil';
import ScrollTracker from '../scroll-tracker';
import Counter from '../counter';

import isMobile from '../../utils/is-mobile';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top } }) => {
        const mobile = isMobile();
        const clusterProgress = mobile ? 0 : isNaN(top) ? 0 : top;

        return (
          <div className="bg-turquoise">
            <div className="relative lg:ml-auto lg:w-4/5 bg-navy px-6 sm:px-12 md:px-20 lg:px-32">
              <div className="max-w-lg">
                <div className="flex flex-col lg:flex-row pt-32 lg:pt-64 lg:pb-48">
                  <div
                    ref={ref}
                    className={`animated lg:w-2/3 ${
                      inView ? 'fadeIn' : 'fadeOut'
                    } highlight-text-expanded text-white`}
                  >
                    During this period of hyper-growth, Paloma maintained{' '}
                    <span
                      className={`transition-delay-500ms transition-color transition-500ms ${
                        inView ? 'text-special-red' : 'text-white'
                      }`}
                    >
                      99.999% uptime
                    </span>{' '}
                    as the Afterpay consumer platform grew to service over{' '}
                    <span
                      className={`transition-delay-1s transition-color transition-500ms ${
                        inView ? 'text-turquoise' : 'text-white'
                      }`}
                    >
                      700 million server requests per week.
                    </span>
                  </div>
                  <div className="w-px h-24 my-8 mx-auto bg-special-red lg:hidden" />
                  <div className="lg:w-1/3 lg:self-end">
                    <div className="text-center lg:text-left">
                      <div
                        className="text-special-red ninety-nine"
                      >
                        <div className="big-stat-text">
                          <Counter
                            inView={inView}
                            b={99.999}
                            interpolate={val =>
                              val < 10 ? '0' + val.toFixed(3) : val.toFixed(3)
                            }
                          />
                          %
                        </div>
                        <div className="date-text text-base lg:text-xl mt-2">Uptime Rate</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-px h-24 my-8  mx-auto bg-special-red lg:hidden" />
                </div>
                <div className="text-white text-center lg:text-left">
                  <div
                    className="big-stat-text"
                  >
                    <SevenHundredMil
                      val={700000000 + ((-top || 0) * (-top || 0) || 0) * 100}
                    />
                  </div>
                  <div className="date-text text-base lg:text-xl mt-2">server requests <span className="block lg:inline">per week</span></div>
                </div>
                <svg
                  className="block mx-auto pb-12"
                  width="235"
                  height="324"
                  viewBox="0 0 235 324"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 100)">
                    <path
                      d="M203.242 91.3079L177.682 112.783L171.865 79.9101L203.242 91.3079Z"
                      stroke="#C99CEF"
                      strokeWidth="2"
                      transform={`translate(0 ${clusterProgress / 5})`}
                    />
                    <path
                      d="M87.4858 195.762L94.9201 202.62L85.2635 205.629L87.4858 195.762Z"
                      stroke="#C99CEF"
                      strokeWidth="2"
                      transform={`translate(0 ${clusterProgress / 6})`}
                    />
                    <path
                      d="M32.2482 103.22L19.2843 107.26L22.2677 94.0132L32.2482 103.22Z"
                      fill="#C99CEF"
                      transform={`translate(0 ${clusterProgress / 8})`}
                    />
                    <path
                      d="M111.826 28.775L132.371 28.612L122.239 46.4859L111.826 28.775Z"
                      fill="#B1F8FC"
                      transform={`translate(0 ${clusterProgress / 7})`}
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        );
      }}
    </ScrollTracker>
  );
};
