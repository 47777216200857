import React from 'react';
import { useInView } from 'react-intersection-observer';

import Graph from './intro/graph';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="flex flex-col lg:flex-row bg-navy">
      <div className="w-full lg:w-1/2 pt-32 md:pt-64 pb-16 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64 lg:pr-8 xl:pr-16">
        <p
          ref={ref1}
          className={`body-text text-turquoise animated ${
            inView1 ? 'fadeIn' : 'fadeOut'
          }`}
        >
          The new platform arrived just in time, as Afterpay was about to
          undergo a period of hyper growth unlike any technology company in
          Australia's history. Between{' '}
          <span className="text-special-red">May 2017</span> and{' '}
          <span className="text-special-red">
            September 2019, every imaginable metric in the company simply took
            off.
          </span>{' '}
          As monthly active users soared from <span className="text-special-red">870,000</span> to <span className="text-special-red">4.7 million</span>, web and mobile
          traffic went through the roof.
        </p>
      </div>
      <div className="w-full lg:w-1/2 pt-16 lg:pt-64 pb-16 px-8 sm:px-12 md:px-32 lg:px-16">
        <div id="mau-graph" ref={ref2} className={`${inView2 ? 'in-view' : ''}`}>
          <div
            className="lg:-mt-24"
          >
            <div className="stat-heading-text text-special-red">
              Customer Growth
            </div>
            <div className="highlight-text text-turquoise">
              Monthly Active Users
            </div>
          </div>
          <div
            className={`w-full max-w-sm mt-12 animated ${
              inView2 ? 'fadeIn' : 'fadeOut'
            }`}
          >
            <Graph className="block -ml-5" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};
