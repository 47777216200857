import React from 'react';

import SpriteWrap from '../sprite-wrap';
import StickyScroller from '../../components/sticky-scroller';
import CartSprite from '../../images/05-growth/cart-sprite.inline.svg';

import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const Cart = () => {
  return (
    <div className="relative z-10">
      <StickyScroller>
        {({ scrollProgress, topProgress, rect, height }) => {
          const mobile = isMobile();
          const clusterProgress = mobile ? 0 : topProgress;

          return (
            <div className="relative w-full overflow-hidden flex pt-16 px-6 sm:px-12 md:px-20 lg:pl-40 lg:pr-8 xl:pl-48 h-full">
              <div className="relative z-10 max-w-2xl mx-auto lg:self-center">
                <div className="flex flex-col lg:flex-row">
                  <div className="self-center lg:w-1/2">
                    <p
                      className={`highlight-text-expanded text-navy mb-12 animated ${
                        topProgress >= 0.5 ? 'fadeIn' : 'fadeOut'
                      }`}
                    >
                      <span className="bg-turquoise">
                        Before long, the Afterpay platform was transacting over
                        10% of all e-commerce transactions in Australia.
                      </span>
                    </p>
                  </div>
                  <div
                    className={`-mx-16 sm:mx-0 lg:w-1/2 animated delay-250ms ${
                      topProgress >= 0.5 ? 'fadeIn' : 'fadeOut'
                    }`}
                  >
                    <div
                      className="overflow-hidden relative w-full"
                      style={{ maxWidth: 682, paddingBottom: '82%' }}
                    >
                      <div className="absolute pin">
                        <SpriteWrap
                          inView={scrollProgress * height}
                          height={height}
                          numFrames={20}
                        >
                          <CartSprite className="block" />
                        </SpriteWrap>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="absolute pin-y pin-r bg-special-red"
                style={{
                  width: '25vw',
                  background: 'linear-gradient(0deg, #ffffff 0%, #ff5a7d 100%)',
                }}
              />
              {!mobile && (
                <svg
                  className="absolute mx-auto pin-x pin-b z-10"
                  width="264"
                  height="463"
                  viewBox="0 0 264 463"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: 'translateY(50%)' }}
                >
                  <g transform="translate(0 50)">
                    <path
                      d="M192.972 175.472L112.748 237.147L99.4477 136.834L192.972 175.472Z"
                      stroke="#C99CEF"
                      strokeWidth="2"
                      transform={`translate(0 ${clusterProgress * -50})`}
                    />
                    <path
                      d="M13.8913 14.1739L46.5207 21.5905L23.7831 46.1401L13.8913 14.1739Z"
                      fill="#B1F8FC"
                      transform={`translate(0 ${clusterProgress * -20})`}
                    />
                    <path
                      d="M242.805 74.2814L240.848 113.002L208.293 91.9474L242.805 74.2814Z"
                      stroke="#C99CEF"
                      strokeWidth="2"
                      transform={`translate(0 ${clusterProgress * -30})`}
                    />
                  </g>
                </svg>
              )}
            </div>
          );
        }}
      </StickyScroller>
    </div>
  );
};

export default LazyLoadWrap(Cart, '200vh');

