import React from 'react';
import { useInView } from 'react-intersection-observer';

import APLogo from '../../images/05-growth/ap-logo.inline.svg';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refAP, inViewAP] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48 pb-24 xl:pb-48">
      <div className="relative max-w-xl mx-auto">
        <svg
          className={`vapor-fade-in absolute pin-t pin-l ml-10 sm:ml-16 lg:ml-20 ${inView ? 'in-view' :''}`}
          width="14.79166667%"
          height="39.375%"
          viewBox="0 0 142 378"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="141.765"
            y="378"
            width="141"
            height="51.7076"
            transform="rotate(-180 141.765 378)"
            fill="#B1F8FC"
          />
          <rect
            x="141.765"
            y="304.005"
            width="141"
            height="50.8161"
            transform="rotate(-180 141.765 304.005)"
            fill="#B1F8FC"
          />
          <rect
            x="141.765"
            y="230.901"
            width="141"
            height="37.4434"
            transform="rotate(-180 141.765 230.901)"
            fill="#B1F8FC"
          />
          <rect
            x="141.765"
            y="171.17"
            width="141"
            height="27.6368"
            transform="rotate(-180 141.765 171.17)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.9"
            x="141.765"
            y="125.702"
            width="141"
            height="20.5047"
            transform="rotate(-180 141.765 125.702)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.8"
            x="141.765"
            y="93.6084"
            width="141"
            height="16.0472"
            transform="rotate(-180 141.765 93.6084)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.6"
            x="141.765"
            y="67.7546"
            width="141"
            height="9.80661"
            transform="rotate(-180 141.765 67.7546)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.6"
            x="141.765"
            y="51.7072"
            width="141"
            height="6.24057"
            transform="rotate(-180 141.765 51.7072)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.5"
            x="141.765"
            y="40.1179"
            width="141"
            height="5.34906"
            transform="rotate(-180 141.765 40.1179)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.3"
            x="141.765"
            y="28.528"
            width="141"
            height="3.56604"
            transform="rotate(-180 141.765 28.528)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.3"
            x="141.765"
            y="18.7214"
            width="141"
            height="2.67453"
            transform="rotate(-180 141.765 18.7214)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.2"
            x="141.765"
            y="8.91443"
            width="141"
            height="1.78302"
            transform="rotate(-180 141.765 8.91443)"
            fill="#B1F8FC"
          />
          <rect
            opacity="0.1"
            x="141.765"
            y="0.891113"
            width="141"
            height="0.89151"
            transform="rotate(-180 141.765 0.891113)"
            fill="#B1F8FC"
          />
        </svg>
        <div ref={ref} className="flex">
          <div
            className="mt-12 sm:mt-16 md:mt-20 lg:mt-24 xl:mt-32"
            style={{
              transform: 'rotate(-90deg) translateX(-50%)',
              transformOrigin: 'top left',
            }}
          >
            <div
              className={`big-stat-text text-special-red animated ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              #2
            </div>
            <div
              className={`big-stat-text text-neon-lavender animated delay-500ms ${
                inView ? 'fadeIn' : 'fadeOut'
              }`}
            >
              In AUS
            </div>
          </div>
        </div>
        <div ref={refAP} className="relative">
          <APLogo
            width="100%"
            height="100%"
            className={`block mx-auto animated ${
              inViewAP ? 'fadeIn' : 'fadeOut'
            }`}
          />
          <div
            className={`absolute center-y highlight-text text-navy xl:text-center xl:bg-turquoise animated delay-500ms ${
              inViewAP ? 'fadeIn' : 'fadeOut'
            }`}
          >
            <span className="bg-turquoise">In 2019 Afterpay has become the second largest source of referral
            traffic in Australia after Google</span>
          </div>
        </div>
      </div>
    </div>
  );
};
