import React from 'react';

export default ({ className = '', offsetY = 0, a = "#FF5A7D", b = "#143673", c = "#FF5A7D", d = "#C99CEF", e = "#C99CEF" }) => (
  <svg
    className={`block ${className}`}
    width="431"
    height="340"
    viewBox="0 0 431 340"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0 50)">
      <path
        d="M102.849 209.574L92.6064 262.648L51.7638 227.241L102.849 209.574Z"
        fill={b}
        transform={`translate(0 ${(offsetY) / 12})`}
      />
      <path
        d="M355.425 213.712L255.992 238.865L283.926 140.177L355.425 213.712Z"
        stroke={a}
        strokeWidth="2"
        transform={`translate(0 ${(offsetY) / 6})`}
      />
      <path
        d="M26.8542 164.397L34.9923 157.494L36.9016 167.993L26.8542 164.397Z"
        stroke={d}
        strokeWidth="2"
        transform={`translate(0 ${(offsetY) / 16})`}
      />
      <path
        d="M323.37 34.4126L294.105 55.9011L290.128 19.8131L323.37 34.4126Z"
        fill={c}
        transform={`translate(0 ${(offsetY) / 14})`}
      />
      <path
        d="M143.577 114.128L151.64 120.349L142.222 124.221L143.577 114.128Z"
        fill={e}
        transform={`translate(0 ${(offsetY) / 20})`}
      />
    </g>
  </svg>
);
