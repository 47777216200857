import React from 'react';

export default ({ children, inView, height, numFrames }) => {
  let translateY = parseInt(inView / (height / numFrames)) * (100 / numFrames);

  if (translateY <= 0) translateY = 0;
  if (translateY >= 100 - 100 / numFrames) translateY = 100 - 100 / numFrames;

  return (
    <div style={{ transform: `translateZ(0) translateY(-${translateY}%)` }}>{children}</div>
  );
};
