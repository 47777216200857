import React from 'react';

import numberWithCommas from '../../../utils/number-with-commas';

export default ({ val = 500000000 }) => {
  return (
    <div>
      {numberWithCommas(Math.floor(val))}
    </div>
  );
};
