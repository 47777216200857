import React from 'react';

import Hero from '../../components/05-growth/hero';
import Intro from '../../components/05-growth/intro';
import CartLaptop from '../../components/05-growth/cart-laptop';
import Uptime from '../../components/05-growth/uptime';
import ByTheNumbers from '../../components/05-growth/by-the-numbers';
import SearchTraffic from '../../components/05-growth/search-traffic';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>05. Hyper Growth</div>
      </div>
      <Hero />
      <Intro />
      <CartLaptop />
      <Uptime />
      <ByTheNumbers />
      <SearchTraffic />
    </div>
  );
};
